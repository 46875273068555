import { media, styled } from '@/stitches.config';
import { keyframes } from '@stitches/react';
import { animate, motion, useMotionValue } from 'framer-motion';
import { MouseEventHandler, useEffect } from 'react';
import { useMedia, useWindowSize } from 'react-use';

const RADIUS = 32;
const STROKE_WIDTH = 9;
const NORMALIZED_RADIUS = RADIUS - STROKE_WIDTH * 2;
const CIRCUMFERENCE = NORMALIZED_RADIUS * 2 * Math.PI;
const STROKE_DASHOFFSET = CIRCUMFERENCE - (0 / 20) * CIRCUMFERENCE;

export function CaseTransitionSpinner() {
  const durationInSeconds = 5;

  const { width, height } = useWindowSize();
  const isLargeScreen = useMedia(media.bp3, true);

  const x = useMotionValue(width / 2);
  const y = useMotionValue(height / 2);

  useEffect(() => {
    if (!isLargeScreen) {
      x.set(0);
      y.set(0);
    } else {
      x.set(width / 2);
      y.set(height / 2);
    }
  }, [isLargeScreen, x, y, width, height]);

  const handleMouse: MouseEventHandler = (event) => {
    if (!isLargeScreen) return;

    animate(x, event.clientX);
    animate(y, event.clientY);
  };

  return (
    <CaseTransitionSpinnerContainer onMouseMove={handleMouse}>
      <CaseTransitionSpinnerWrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 1 } }}
        exit={{ opacity: 0 }}
        style={{ x, y }}
      >
        <SvgWrapper>
          <Svg height={RADIUS * 2} width={RADIUS * 2}>
            <Circle
              cx={RADIUS}
              cy={RADIUS}
              r={NORMALIZED_RADIUS}
              fill="transparent"
              stroke="white"
              strokeWidth={STROKE_WIDTH}
              strokeDasharray={CIRCUMFERENCE + ' ' + CIRCUMFERENCE}
            />
            <Circle
              fill="transparent"
              stroke="black"
              strokeWidth={STROKE_WIDTH}
              strokeDasharray={CIRCUMFERENCE + ' ' + CIRCUMFERENCE}
              css={{
                animation: `${scaleUp(
                  STROKE_DASHOFFSET
                )} ${durationInSeconds}s infinite`,
                animationTimingFunction: 'ease-in-out',
                transform: 'rotate(-90deg) scaleY(-1)',
                transformOrigin: 'center',
                willChange: 'stroke-dashoffset',
              }}
              cx={RADIUS}
              cy={RADIUS}
              r={NORMALIZED_RADIUS}
            />
          </Svg>
        </SvgWrapper>
      </CaseTransitionSpinnerWrapper>
    </CaseTransitionSpinnerContainer>
  );
}

const scaleUp = (offset: number) => {
  return keyframes({
    '0%': {
      strokeDashoffset: 0,
    },
    '50%': {
      strokeDashoffset: offset,
    },
    '100%': {
      strokeDashoffset: offset * 2,
    },
  });
};

const CaseTransitionSpinnerContainer = styled('div', {
  position: 'fixed',
  inset: 0,
  zIndex: 110,

  '@bp3': {
    cursor: 'none',
  },
});

const CaseTransitionSpinnerWrapper = styled(motion.div, {
  position: 'fixed',
  bottom: -20,
  right: -20,

  '@bp3': {
    top: 0,
    left: 0,
    pointerEvents: 'none',
  },
});

const SvgWrapper = styled('div', {
  transform: 'translate(-50%, -50%) scale(0.66)',

  '@bp3': {
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

const Svg = styled('svg', {
  overflow: ' auto',
  animation: `${keyframes({
    '100%': {
      transform: 'rotate(360deg)',
    },
  })} 2s linear infinite`,
});

const Circle = styled('circle');
