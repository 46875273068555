import { useCaseTransitionStore } from '@/hooks/useCaseTransitionStore';
import { styled } from '@/stitches.config';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { CaseTransitionSpinner } from './case-transition-spinner';
import { HeaderContainer } from './header/shared/header-container';
import { LogoWrapper } from './header/shared/logo-wrapper';
import { Logo } from './logo';

const DURATION = 0.3;
const CURVE = [0.73, 0, 0.13, 1];

export function CaseTransitionIn() {
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const router = useRouter();
  const { treemap, navigateTo, resetCaseTransitionProps } =
    useCaseTransitionStore();

  const handleAnimationComplete = () => {
    if (!navigateTo) {
      resetCaseTransitionProps();
      return;
    }

    setShouldNavigate(true);
  };

  useEffect(() => {
    if (!shouldNavigate || !navigateTo) return;

    // To be sure all the transition elements have settled,
    // we wait for a short moment before proceding and navigating to the next page.
    const timeout = setTimeout(() => router.push(navigateTo), 20);

    return () => {
      clearTimeout(timeout);
    };
  }, [shouldNavigate, router, navigateTo]);

  return (
    <>
      {treemap &&
        // @TODO fix any CFW-697
        treemap.map((leaf: any, index: number) => (
          <Leaf
            key={index}
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            exit={{ scaleY: 1 }}
            onAnimationComplete={handleAnimationComplete}
            transition={{ duration: DURATION, ease: CURVE }}
            css={{
              top: `${leaf.y0}%`,
              left: `${leaf.x0}%`,
              width: `${leaf.x1 - leaf.x0}%`,
              height: `${leaf.y1 - leaf.y0}%`,
              backgroundColor: leaf.data.color,
              transformOrigin: 'bottom',
            }}
          />
        ))}

      <CaseTransitionSpinner />
      <CFLogo animateIn={true} />
    </>
  );
}

export function CaseTransitionOut() {
  const { treemap, resetCaseTransitionProps } = useCaseTransitionStore();

  return (
    <>
      {treemap &&
        // @TODO fix any CFW-697
        treemap.map((leaf: any, index: number) => (
          <Leaf
            key={index}
            initial={{ scaleY: 1 }}
            animate={{ scaleY: 0 }}
            exit={{ scaleY: 0 }}
            transition={{ duration: DURATION * 2, ease: CURVE }}
            onAnimationComplete={resetCaseTransitionProps}
            css={{
              top: `${leaf.y0}%`,
              left: `${leaf.x0}%`,
              width: `${leaf.x1 - leaf.x0}%`,
              height: `${leaf.y1 - leaf.y0}%`,
              backgroundColor: leaf.data.color,
              transformOrigin: 'top',
            }}
          />
        ))}
      <CFLogo animateIn={false} />
    </>
  );
}

function CFLogo({ animateIn }: { animateIn: boolean }) {
  return (
    <LogoHeaderContainer
      initial={{
        opacity: animateIn ? 0 : 1,
      }}
      animate={{
        opacity: 1,
        transition: {
          delay: animateIn ? 0.2 : 0,
        },
      }}
    >
      <HeaderContainer>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
      </HeaderContainer>
    </LogoHeaderContainer>
  );
}

const Leaf = styled(motion.div, {
  position: 'fixed',
  zIndex: 100,
  overflow: 'hidden',
});

const LogoHeaderContainer = styled(motion.div, {
  position: 'fixed',
  zIndex: 110,
  color: 'white',
});
